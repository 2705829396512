<script>
    import spinner from "../assets/Spinner-1s-200px.svg";
</script>

<div
    class="flex items-center justify-between px-6 py-1 bg-light-50 rounded-full shadow"
>
    <strong>Loading...</strong>
    <img src={spinner} alt="Loading spinner" class="w-20" />
</div>
