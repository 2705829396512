<script>
    import { onMount } from "svelte";

    export let links = [];

    onMount(() => {
        addLink({ url: "" });
    });

    function addLink(link) {
        links = [...links, link];
    }

    function removeLink(index) {
        links = [...links.slice(0, index), ...links.slice(index + 1)];
    }

    function onEditItem(index) {
        if (index === links.length - 1 && links[index].url) {
            // last item and not empty
            addLink({ url: "" });
        } else if (
            index === links.length - 2 &&
            !links[index].url &&
            !links[index + 1].url
        ) {
            // second to last item and empty
            removeLink(index + 1);
        } else if (index < links.length - 2 && !links[index].url) {
            // any previous item that's empty
            removeLink(index);
        }
    }
</script>

<div class="flex flex-col gap-2 mb-4">
    {#if links}
        {#each links as link, index}
            <div class="flex flex-row gap-1">
                <input
                    class="flex-grow"
                    type="url"
                    bind:value={link.url}
                    placeholder="Enter a web url..."
                    on:input={() => onEditItem(index)}
                />
                <!-- <button
                    type="button"
                    class="fa-solid fa-trash-can"
                    on:click={() => removeLink(index)}
                /> -->
            </div>
        {/each}
    {/if}
    <!-- <button type="button" on:click={() => addLink({ url: "url" })}>
        <i class="fa-solid fa-plus" /> Add link
    </button> -->
</div>
