<script>
    import { onDestroy, onMount } from "svelte";
    import { popNavStack, pushToNavStack, user } from "./store";
    import { openModal } from "svelte-modals";
    import AlertModal from "./Modals/AlertModal.svelte";
    import { logout, updateProfile } from "./api";
    import ConfirmModal from "./Modals/ConfirmModal.svelte";
    import { navigate } from "svelte-navigator";
    import mixpanel from "mixpanel-browser";

    let name = $user.name;
    let email = $user.email;

    let changed = false;
    let saving = false;

    onMount(() => {
        pushToNavStack("/");
        mixpanel.track("Loaded a Page", {
            category: "Web Client",
            name: "Profile",
        });
    });

    onDestroy(() => {
        popNavStack();
    });

    async function saveChanges() {
        saving = true;
        try {
            await updateProfile({
                name,
                email,
            });
            changed = false;
        } catch {
            openModal(AlertModal, {
                title: "Oops!",
                message: "Failed to update your profile. Please try again.",
            });
        } finally {
            saving = false;
        }
    }

    function confirmLogout() {
        openModal(ConfirmModal, {
            title: "Logout",
            message: "Are you sure you want to logout?",
            confirmText: "Yes, I'm sure.",
            cancelText: "No, take me back!",
            onConfirm: () => {
                logout();
                navigate("/");
            },
        });
    }
</script>

<h2>Profile</h2>

<form on:submit|preventDefault={saveChanges} on:input={() => (changed = true)}>
    <fieldset class="flex flex-col gap-2" disabled={saving}>
        <input
            type="text"
            placeholder="Username..."
            bind:value={name}
            required
        />
        <input
            type="email"
            placeholder="Email address..."
            bind:value={email}
            required
        />
        <button type="submit" hidden={!changed}>
            <i class="fa-solid fa-floppy-disk" /> Save Changes
        </button>
        <button type="button" on:click={confirmLogout}> Logout </button>
    </fieldset>
</form>

<p>
    To change your profile picture, <a
        href="https://gravatar.com"
        target="_blank"
        rel="noopener noreferrer nofollow">create a Gravitar</a
    > with your account's email address.
</p>

<style lang="scss">
    p {
        @apply my-3;
        a {
            @apply underline;
        }
    }
</style>
