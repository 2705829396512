<script>
    import { navigate } from "svelte-navigator";
    import { logout, resetPassword, resetPasswordComplete } from "./api";

    const urlParams = new URLSearchParams(window.location.search);
    let token = urlParams.get("token");
    let userId = urlParams.get("userId");

    let email;
    let password1;
    let password2;
    let error = "";

    let sending = false;
    let success = false;

    async function doReset() {
        sending = true;
        try {
            await resetPassword(email);
            success = true;
        } finally {
            sending = false;
        }
    }

    async function doResetComplete() {
        sending = true;
        error = "";
        try {
            if (password1 === password2) {
                await resetPasswordComplete(token, userId, password1);
                logout();
                navigate("/");
            } else {
                error = "Passwords don't match.";
            }
        } catch (e) {
            alert(
                "There was a problem resetting your password. Please try again."
            );
        } finally {
            sending = false;
        }
    }
</script>

{#if token && userId}
    <div class="flex flex-col items-center">
        <h2 class="text-center">Complete Password Reset</h2>
        <form on:submit|preventDefault={doResetComplete}>
            <fieldset class="flex flex-col gap-3 w-96" disabled={sending}>
                <input
                    type="password"
                    placeholder="Password"
                    minlength="6"
                    bind:value={password1}
                    required
                />
                <input
                    type="password"
                    placeholder="Retype password"
                    minlength="6"
                    bind:value={password2}
                    required
                />
                <div class="text-red-500" hidden={!error}>{error}</div>
                <button type="submit">Set Password</button>
            </fieldset>
        </form>
    </div>
{:else}
    <div class="flex flex-col items-center">
        <h2 class="text-center">Password Reset</h2>
        {#if success}
            <p>
                Submitted. If the email entered matches a registered user, they
                will receive an email with next steps.
            </p>
        {:else}
            <form on:submit|preventDefault={doReset}>
                <fieldset class="flex flex-col gap-3 w-96" disabled={sending}>
                    <p>
                        Enter your email address bellow to reset your password.
                    </p>
                    <input
                        type="email"
                        placeholder="Email address..."
                        bind:value={email}
                        required
                    />
                    <button type="submit">Reset Password</button>
                </fieldset>
            </form>
        {/if}
    </div>
{/if}
