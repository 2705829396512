<script>
    import { createList, getLists } from "./api";
    import Loading from "./lib/Loading.svelte";
    import Error from "./lib/Error.svelte";
    import { openModal } from "svelte-modals";
    import AlertModal from "./Modals/AlertModal.svelte";
    import { listBookmarks } from "./store";
    import ListThumb from "./ListThumb.svelte";
    import { onMount } from "svelte";
    import mixpanel from "mixpanel-browser";

    let lists = [];

    onMount(() => {
        mixpanel.track("Loaded a Page", {
            category: "Web Client",
            name: "Dashboard",
        });
    });

    async function refresh() {
        return (lists = await getLists());
    }

    async function newList() {
        try {
            await createList();
            refresh();
        } catch (e) {
            openModal(AlertModal, {
                title: "Oops!",
                message:
                    e.response?.data?.errorCode === "MaxWishlists"
                        ? "You've reached that max number of wishlists."
                        : "There was an error creating a new wishlist. Please try again.",
            });
        }
    }
</script>

{#await refresh()}
    <Loading />
{:then}
    <div class="flex flex-col items-center">
        <h2 class="text-center">Your wishlists</h2>
        <div
            id="listRoot"
            class="flex flex-row justify-center gap-4 flex-wrap mb-4"
        >
            {#if lists.length === 0}
                <em>
                    Looks empty here, create you first wishlist! <i
                        class="fa-solid fa-arrow-turn-down"
                    />
                </em>
            {/if}
            {#each lists as list}
                <ListThumb
                    name={list.name}
                    id={list._id}
                    bgImage={list.bgImage?.urlSmall}
                />
            {/each}
        </div>
        <button class="btn btn-primary" on:click={newList}>
            <i class="fa-solid fa-plus" /> New Wishlist
        </button>

        <h2 class="mt-4">Bookmarked lists:</h2>
        <div
            id="listRoot"
            class="flex flex-row justify-center gap-4 flex-wrap mb-4"
        >
            {#if $listBookmarks.length === 0}
                <em>
                    You can bookmark wishlists that are shared with you. Then
                    they will appear here for future reference.
                </em>
            {/if}
            {#each $listBookmarks as b}
                <ListThumb
                    name={`${b.list.name} by ${b.list.owner.name}`}
                    id={b.list._id}
                    bgImage={b.list.bgImage?.urlSmall}
                />
            {/each}
        </div>
    </div>
{:catch}
    <Error msg="There was an error loading your wishlists." />
{/await}
