<script>
    import { link, navigate } from "svelte-navigator";
    import { login, register } from "./api";

    let email, password1, password2, name;
    let showLogin = true;
    let submitting = false;
    let errors = [];

    let captchaDiv;
    let captcha;
    let captchaToken;

    $: if (captchaDiv) {
        captcha = hcaptcha.render(captchaDiv, {
            sitekey: "875c3ecd-1641-4177-83a7-af8fd18bfe08",
            callback: (e) => {
                captchaToken = e;
            },
        });
    }

    async function submitForm() {
        submitting = true;
        errors = [];
        try {
            if (showLogin) {
                await login(email, password1);
            } else {
                if (password1 !== password2) {
                    errors = ["Passwords don't match."];
                } else if (!captchaToken) {
                    hcaptcha.execute(captcha);
                } else {
                    await register(email, password1, name, captchaToken);
                    navigate("/");
                }
            }
        } catch (e) {
            if (e?.response?.data?.error) errors = [e?.response?.data?.error];
            else if (e?.response?.data?.errors)
                errors = e?.response?.data?.errors?.map((i) => i.msg);
            else if (e?.response?.status === 401)
                errors = [
                    "Failed to login, please check your email and password.",
                ];
            else errors = ["An error occured, please try again."];
            if (captcha) {
                hcaptcha.reset(captcha);
                captchaToken = null;
            }
        } finally {
            submitting = false;
        }
    }
</script>

<div class="flex justify-center">
    <div class="flex flex-col w-96">
        <h1 class="text-center">Welcome to Gift Kiwi</h1>
        <p>
            Come on in and make a wishlist to share with your friends and
            family!
        </p>

        <h2 class="text-center my-2">{showLogin ? "Login" : "Register"}</h2>

        <form on:submit|preventDefault={submitForm}>
            <fieldset
                disabled={submitting}
                class="flex flex-col gap-3 flex-grow"
            >
                {#if !showLogin}
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        bind:value={name}
                        required
                    />
                {/if}
                <input
                    type="email"
                    class="form-control"
                    placeholder="Email address"
                    bind:value={email}
                    required
                />
                <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    minlength="6"
                    bind:value={password1}
                    required
                />
                {#if !showLogin}
                    <input
                        type="password"
                        class="form-control"
                        placeholder="Retype password"
                        minlength="6"
                        bind:value={password2}
                        required
                    />
                    <div bind:this={captchaDiv} class="flex justify-center" />
                {/if}
                <div
                    class="flex flex-col items-center text-red-500"
                    hidden={!errors}
                >
                    {#each errors as e}
                        <p>{e}</p>
                    {/each}
                </div>
                <button type="submit" class="btn btn-primary"
                    >{showLogin ? "Login" : "Register"}</button
                >
                <button
                    type="button"
                    class="btn btn-outline-secondary"
                    on:click={() => (showLogin = !showLogin)}
                    >{showLogin
                        ? "I'm new here, sign me up!"
                        : "I have an account already."}</button
                >
                {#if showLogin}
                    <a href="/passwordReset" use:link class="text-center">
                        I forgot my password
                    </a>
                {:else}
                    <p class="text-center">
                        By registering you agree to the
                        <a
                            href="/terms-of-use"
                            target="_blank"
                            use:link
                            class="underline"
                        >
                            terms of use
                        </a>
                        and
                        <a href="/privacy" target="_blank" use:link>
                            privacy policy
                        </a>.
                    </p>
                {/if}
            </fieldset>
        </form>
    </div>
</div>

<style>
    a {
        @apply underline;
    }
</style>
