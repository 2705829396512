<script>
    import { onDestroy, onMount } from "svelte";
    import { Route, useNavigate } from "svelte-navigator";
    import {
        addListBookmark,
        addWishToList,
        deleteListBookmark,
        getList,
    } from "./api";
    import Error from "./lib/Error.svelte";
    import Loading from "./lib/Loading.svelte";
    import {
        backgroundImage,
        popNavStack,
        pushToNavStack,
        updateBookmarks,
        listBookmarks,
        user,
    } from "./store";
    import Wish from "./Wish.svelte";
    import WishlistSettings from "./WishlistSettings.svelte";
    import { openModal } from "svelte-modals";
    import AlertModal from "./Modals/AlertModal.svelte";
    import ConfirmModal from "./Modals/ConfirmModal.svelte";
    import mixpanel from "mixpanel-browser";
    import ShareModal from "./Modals/ShareModal.svelte";

    export let listId;

    const navigate = useNavigate();

    let list;
    let listPromise = refresh();

    let newWish = "";
    let saving = false;

    $: backgroundImage.set(list?.bgImage);

    onMount(() => {
        pushToNavStack("/");
        mixpanel.track("Loaded a Page", {
            category: "Web Client",
            name: "List",
            list_id: listId,
        });
    });

    onDestroy(() => {
        popNavStack();
        backgroundImage.set();
    });

    async function refresh() {
        console.log("Loading list");
        return (list = await getList(listId));
    }

    async function addNewWish() {
        if (!newWish || saving) return;

        const submittingWish = newWish;
        newWish = "";

        saving = true;
        try {
            await addWishToList(listId, { name: submittingWish });
            await refresh();
        } catch (e) {
            openModal(AlertModal, {
                title: "Oops!",
                message:
                    e.response?.data?.errorCode === "MaxWishes"
                        ? "You've reached that max number of wishes."
                        : "There was an error adding the new wish. Please try again.",
            });
            newWish = submittingWish;
        } finally {
            saving = false;
        }
    }

    async function bookmarkThis() {
        if (saving) return;

        saving = true;
        try {
            await addListBookmark(listId);
            await updateBookmarks();
        } finally {
            saving = false;
        }
    }

    async function removeBookmark() {
        if (saving) return;

        openModal(ConfirmModal, {
            title: "Remove Bookmark",
            message:
                "Are your sure you want to un-bookmark this list?\nYou will no longer be able to access this list from your dashboard.",
            onConfirm: async () => {
                saving = true;
                try {
                    await deleteListBookmark(listId);
                    await updateBookmarks();
                } finally {
                    saving = false;
                }
            },
        });
    }

    function share() {
        if (!list) return;

        openModal(ShareModal, {
            list,
        });
    }

    const sortWishes = (a, b) => a.name.localeCompare(b.name);
</script>

<div class="bg-light-900 bg-opacity-70 rounded-2xl p-8 <sm:p-5 text-dark-800">
    <Route path="/wish/:wishId" let:params>
        <Wish
            wishId={params.wishId}
            on:wishDeleted={refresh}
            on:wishClaimed={refresh}
        />
    </Route>
    <Route path="/*">
        {#await listPromise}
            <Loading />
        {:then}
            <Route path="/settings">
                <WishlistSettings {list} on:changed={refresh} let:params />
            </Route>
            <Route path="/">
                <div class="flex flex-col">
                    <div class="flex flex-row justify-between mb-2">
                        <div>
                            <h2>{list.name}</h2>
                            <em>By {list.owner.name}</em>
                        </div>
                        <div class="flex flex-row gap-8 text-3xl">
                            <i
                                class="fa-solid fa-share-nodes cursor-pointer"
                                on:click={share}
                            />
                            {#if $user && list.owner._id === $user._id}
                                <i
                                    class="fa-solid fa-pen-to-square cursor-pointer"
                                    on:click={() => navigate(`settings`)}
                                />
                            {:else if $user && $listBookmarks.find((b) => b.list._id === listId)}
                                <i
                                    class="fa-solid fa-bookmark cursor-pointer"
                                    on:click={removeBookmark}
                                    disabled={saving}
                                />
                            {:else if $user}
                                <i
                                    class="fa-regular fa-bookmark cursor-pointer"
                                    on:click={bookmarkThis}
                                    disabled={saving}
                                />
                            {/if}
                        </div>
                    </div>
                    {#if list.description}
                        <p class="mb-3">{list.description}</p>
                    {/if}
                    {#if list.wishes.length > 0}
                        <div class="flex flex-col gap-3 mb-3">
                            {#each list.wishes.sort(sortWishes) as wish}
                                <div
                                    class="flex flex-row justify-between items-center bg-light-50 rounded-full p-3 shadow-md cursor-pointer"
                                    on:click={() =>
                                        navigate(`wish/${wish._id}`)}
                                >
                                    <span>{wish.name}</span>
                                    {#if $user && wish.claimant?._id === $user._id}
                                        <i
                                            class="fa-solid fa-circle-check text-green-600"
                                        />
                                    {:else if $user && wish.claimant}
                                        <i
                                            class="fa-solid fa-circle-check text-gray-600"
                                        />
                                    {:else if $user && $user._id !== list.owner._id}
                                        <i
                                            class="fa-regular fa-circle text-gray-600"
                                        />
                                    {/if}
                                </div>
                            {/each}
                        </div>
                    {:else}
                        <em class="mb-2">
                            List is empty. Try adding an item!
                        </em>
                    {/if}
                    {#if $user && list.owner._id === $user._id}
                        <form
                            class="flex flex-col sm:flex-row gap-3 sm:gap-1"
                            on:submit|preventDefault={addNewWish}
                        >
                            <input
                                class="flex-grow min-w-0"
                                type="text"
                                bind:value={newWish}
                                placeholder="Enter item name..."
                                required
                            />
                            <button
                                type="submit"
                                disabled={!newWish}
                                class="flex flex-row items-center gap-1 justify-center"
                            >
                                <i class="fa-solid fa-plus" />Add
                            </button>
                        </form>
                    {/if}
                </div>
            </Route>
        {:catch}
            <Error msg="There was an error loading your wishlist." />
        {/await}
    </Route>
</div>
