<script>
    import { onMount } from "svelte";
    import { getOpenGraph } from "./api";

    export let links = [];

    let ogLinks = [];

    onMount(async () => {
        ogLinks = links;
        ogLinks = await Promise.all(
            links.map(async (l) => {
                try {
                    const d = await getOpenGraph(l.url);
                    return { ...d, ...l }; // Overwrite the url prop from the API
                } catch {
                    return l;
                }
            })
        );
    });
</script>

<div class="flex flex-col items-center mb-4 ">
    <div class="flex flex-col items-stretch gap-2 max-w-40rem">
        {#if ogLinks?.length}
            {#each ogLinks as link}
                <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    class="flex flex-col g gap-1 bg-white rounded-2xl p-8"
                >
                    {#if link.image}
                        <div class="flex justify-center h-70 mb-2">
                            <img
                                src={link.image}
                                alt={link.imageAlt}
                                class="rounded-xl overflow-hidden max-h-full"
                            />
                        </div>
                    {/if}
                    {#if link.siteName}
                        <p class="text-gray-400">{link.siteName}</p>
                    {/if}
                    {#if link.title}
                        <h3 style="line-height: 1.4rem;">{link.title}</h3>
                    {:else}
                        <p>{link.url}</p>
                    {/if}
                    {#if link.description}
                        <p class="ogDescription">{link.description}</p>
                    {/if}
                </a>
            {/each}
        {/if}
    </div>
</div>

<style lang="scss">
    .ogDescription {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>
