<script>
    import { link } from "svelte-navigator";

    export let name;
    export let id;
    export let bgImage;
</script>

<div
    class="bg-true-gray-500 w-40 h-40 rounded-2xl text-light-50 shadow-md shadow-true-gray-400 bg-cover bg-center overflow-hidden"
    style="background-image: url({bgImage});"
>
    <a
        href={`/list/${id}`}
        class="flex justify-center items-center text-center p-4 w-full h-full bg-dark-300 bg-opacity-20"
        use:link
    >
        {name}
    </a>
</div>
