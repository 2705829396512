<script>
    import { backgroundImage } from "./store.js";
</script>

{#if $backgroundImage}
    <div
        id="backgroundContainer"
        style="background-image: url({$backgroundImage.url});"
    >
        <div id="backgroundContainerCover" />
    </div>
    <div
        class="fixed block left-0 bottom-0 right-0 p-2 px-4 text-light-50 thumbAttrib text-sm -z-1000"
    >
        <span>
            Photo by <a
                href={$backgroundImage.attribUrl +
                    "?utm_source=giftkiwi&utm_medium=referral"}
                target="_blank"
                rel="noopener">{$backgroundImage.attribName}</a
            >
            on
            <a
                href="https://unsplash.com/?utm_source=giftkiwi&utm_medium=referral"
                target="_blank"
                rel="noopener"
            >
                Unsplash
            </a>
        </span>
    </div>
{/if}

<style lang="scss">
    #backgroundContainer {
        display: block;
        position: fixed;
        left: -20px;
        right: -20px;
        top: -20px;
        bottom: -20px;
        background-size: cover;
        background-position: center;
        z-index: -1002;

        // filter: blur(6px);
        // -webkit-filter: blur(6px);
    }

    #backgroundContainerCover {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.137);
        z-index: -1001;
    }

    .thumbAttrib {
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    a {
        @apply underline;
    }
</style>
