<script>
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import { useNavigate, useResolve } from "svelte-navigator";
    import { deleteList, patchList } from "./api";
    import { openModal } from "svelte-modals";
    import ConfirmModal from "./Modals/ConfirmModal.svelte";
    import AlertModal from "./Modals/AlertModal.svelte";
    import { popNavStack, pushToNavStack } from "./store";
    import UnsplashSelector from "./lib/UnsplashSelector.svelte";

    const navigate = useNavigate();
    const resolve = useResolve();

    const dispatch = createEventDispatcher();

    export let list;

    let name = list.name;
    let description = list.description;

    let changed = false;
    let saving = false;

    onMount(() => {
        pushToNavStack(resolve(".."));
    });

    onDestroy(() => {
        popNavStack();
    });

    async function saveChanges() {
        saving = true;
        try {
            await patchList(list._id, {
                name,
                description,
            });
            dispatch("changed");
            navigate("..");
        } catch {
            openModal(AlertModal, {
                title: "Oops!",
                message: "Failed to update wishlist info. Please try again.",
            });
        } finally {
            saving = false;
        }
    }

    async function deleteThis() {
        openModal(ConfirmModal, {
            title: `Deleting "${name}"`,
            message:
                "Are you sure you want to delete this wishlist permanently?",
            onConfirm: async () => {
                saving = true;
                try {
                    await deleteList(list._id);
                    navigate("/");
                } catch {
                    alert("Failed to delete wishlist.");
                } finally {
                    saving = false;
                }
            },
        });
    }
</script>

<form on:submit|preventDefault={saveChanges} on:input={() => (changed = true)}>
    <fieldset class="flex flex-col gap-2 mb-4" disabled={saving}>
        <input
            type="text"
            placeholder="Wishlist name..."
            required
            bind:value={name}
        />
        <textarea
            class="h-30 min-h-12"
            placeholder="Wishlist description..."
            bind:value={description}
        />
        <button type="button" class="bg-danger" on:click={deleteThis}>
            <i class="fa-solid fa-trash-can" /> Delete Wishlist
        </button>
        <button type="submit" hidden={!changed}>
            <i class="fa-solid fa-floppy-disk" /> Save Changes
        </button>
    </fieldset>
    <UnsplashSelector listId={list._id} />
</form>
