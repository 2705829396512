<script>
    import { closeModal } from "svelte-modals";
    import QRCodeStyling from "qr-code-styling";
    import { onMount } from "svelte";
    import logo from "../assets/GiftKiwi.svg";

    // provided by <Modals />
    export let isOpen;

    export let list;

    let linkCopiedNotice = "Copy the link";
    let qrCodeContainer;

    onMount(() => {
        const qr = new QRCodeStyling({
            width: 300,
            height: 300,
            type: "svg",
            data: window.location.href,
            image: logo,
            qrOptions: {
                errorCorrectionLevel: "Q",
            },
            dotsOptions: {
                type: "dots",
            },
            cornersDotOptions: {
                type: "dot",
            },
            cornersSquareOptions: {
                type: "extra-rounded",
            },
        });
        qr.append(qrCodeContainer);
    });

    async function copyLinkToClipboard() {
        try {
            const perm = await navigator.permissions.query({
                name: "clipboard-write",
            });
            if (perm.state === "granted" || perm.state === "prompt") {
                await navigator.clipboard.writeText(window.location.href);
                linkCopiedNotice = "🎉 Link copied! 🎉";
            } else {
                linkCopiedNotice = "Failed to copy link, permission denied.";
            }
        } catch (e) {
            linkCopiedNotice = "Failed to copy link!";
            console.error(e);
        }
    }

    function share() {
        navigator
            .share({
                title: `${list.name} on Gift Kiwi`,
                url: document.location.href,
            })
            .catch((e) => {});
    }
</script>

{#if isOpen}
    <div
        class="fixed left-0 top-0 right-0 bottom-0 bg-black bg-opacity-40 z-1000 flex items-center justify-center"
    >
        <div
            class="w-96 bg-white rounded-2xl flex flex-col gap-2 items-stretch p-6"
        >
            <h3 class="text-center">Share this list:</h3>
            {#if navigator.share}
                <button on:click={share}>
                    Share <i class="fa-solid fa-share-nodes" />
                </button>
            {:else}
                <div class="text-center" hidden={!linkCopiedNotice}>
                    {linkCopiedNotice}
                </div>
                <div class="flex flex-row items-center gap-2">
                    <input
                        class="flex-grow"
                        type="text"
                        value={window.location.href}
                        disabled
                    />
                    <button on:click={copyLinkToClipboard}>
                        <i class="fa-solid fa-copy" /> Copy
                    </button>
                </div>
            {/if}
            <div class="text-center">
                Or have recipients scan the code bellow with their phone camera.
            </div>
            <div
                bind:this={qrCodeContainer}
                class="flex justify-center p-2"
                id="qrCodeContainer"
            />
            <button on:click={closeModal}>Done</button>
        </div>
    </div>
{/if}
