<script>
    import { closeModal } from "svelte-modals";

    // provided by <Modals />
    export let isOpen;

    export let title;
    export let message;
    export let confirmText = "Yes";
    export let cancelText = "No";
    export let onConfirm = () => {};

    function confirm() {
        closeModal();
        onConfirm();
    }
</script>

{#if isOpen}
    <div
        role="dialog"
        class="fixed top-0 bottom-0 left-0 right-0 flex justify-center items-start pointer-events-none"
    >
        <div
            class="flex flex-col pointer-events-auto p-5 rounded-2xl bg-light-50 text-dark-800 mt-20 shadow-2xl max-w-140"
        >
            <h2>{title}</h2>
            <p>{message}</p>
            <div class="flex flex-row mt-2 gap-4">
                <button class="flex-grow" on:click={closeModal}
                    >{cancelText}</button
                >
                <button class="flex-grow" on:click={confirm}
                    >{confirmText}</button
                >
            </div>
        </div>
    </div>
{/if}
