import { setUser as setSentryUser } from "@sentry/svelte";
import { writable } from "svelte/store";
import { getListBookmarks } from "./api";
import mixpanel from "mixpanel-browser";

export const user = writable(null);

export const backgroundImage = writable();

export const backNavTarget = writable("");

const navStack = [];

export function pushToNavStack(url) {
    // console.log(`PUSH TO NAV STACK: ${url}`);
    navStack.push(url);
    backNavTarget.set(url);
}

export function popNavStack() {
    navStack.pop();
    backNavTarget.set(navStack.length === 0 ? "" : navStack.at(-1));
    // console.log(`POP BAV STACK: ${get(backNavTarget)}`);
}

export const listBookmarks = writable([]);

user.subscribe(async (user) => {
    setSentryUser(
        !!user
            ? {
                  email: user.email,
                  id: user._id,
                  username: user.name,
              }
            : null
    );
    if (user) {
        mixpanel.identify(user._id);
        await updateBookmarks();
    }
});

export async function updateBookmarks() {
    listBookmarks.set(await getListBookmarks());
}
