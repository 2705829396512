<script>
    import { useNavigate } from "svelte-navigator";
    import {
        claimWish,
        deleteWish,
        getWish,
        patchWish,
        unclaimWish,
    } from "./api";
    import Error from "./lib/Error.svelte";
    import Loading from "./lib/Loading.svelte";
    import { popNavStack, pushToNavStack, user } from "./store";
    import { openModal } from "svelte-modals";
    import ConfirmModal from "./Modals/ConfirmModal.svelte";
    import AlertModal from "./Modals/AlertModal.svelte";
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    import WishLinksEdit from "./WishLinksEdit.svelte";
    import WishLinksView from "./WishLinksView.svelte";
    import mixpanel from "mixpanel-browser";

    export let wishId;

    const navigate = useNavigate();
    const dispatch = createEventDispatcher();

    let listId;
    let wishName;
    let wishDescription;
    let wishMulticlaim;
    let wishClaimants;
    let wishLinks = [];

    let changed = false;
    let saving = false;

    let loadPromise = getWish(wishId);
    loadPromise.then((w) => {
        wishName = w.name;
        wishDescription = w.description;
        wishClaimants = w.claimants;
        wishMulticlaim = w.multiclaim;
        wishLinks = w.links || [];
        listId = w.list;
    });

    onMount(async () => {
        await loadPromise;
        pushToNavStack(`/list/${listId}`);
        mixpanel.track("Loaded a Page", {
            category: "Web Client",
            name: "Wish",
            wish_id: wishId,
        });
    });

    onDestroy(() => {
        popNavStack();
    });

    async function saveChanges() {
        saving = true;
        try {
            await patchWish(wishId, {
                name: wishName,
                description: wishDescription,
                multiclaim: wishMulticlaim,
                links: wishLinks.filter((l) => l.url),
            });
            navigate("../..");
        } catch (e) {
            openModal(AlertModal, {
                title: "Oops!",
                message: "Failed to update wish. Please try again.",
            });
        } finally {
            saving = false;
        }
    }

    async function deleteThis() {
        openModal(ConfirmModal, {
            title: `Deleting "${wishName}"`,
            message: "Are you sure you want to delete this wish?",
            onConfirm: async () => {
                saving = true;
                try {
                    await deleteWish(wishId);
                    dispatch("wishDeleted", {
                        wishId: wishId,
                    });
                    navigate("../..");
                } finally {
                    saving = false;
                }
            },
        });
    }

    async function claim() {
        saving = true;
        try {
            await claimWish(wishId);
            wishClaimants = (await getWish(wishId)).claimants;
            dispatch("wishClaimed", {
                wishId: wishId,
            });
        } finally {
            saving = false;
        }
    }

    async function unclaim() {
        saving = true;
        try {
            await unclaimWish(wishId);
            wishClaimants = (await getWish(wishId)).claimants;
            dispatch("wishClaimed", {
                wishId: wishId,
            });
        } finally {
            saving = false;
        }
    }
</script>

{#await loadPromise}
    <Loading />
{:then wish}
    {#if $user && wish.owner === $user._id}
        <!-- Wish owned view -->
        <form
            on:submit|preventDefault={saveChanges}
            on:input={() => (changed = true)}
        >
            <fieldset class="flex flex-col gap-2" disabled={saving}>
                <h3>Edit Wish</h3>
                <input
                    type="text"
                    placeholder="Wish name..."
                    required
                    bind:value={wishName}
                />
                <textarea
                    class="h-30 min-h-12"
                    placeholder="Description"
                    bind:value={wishDescription}
                />
                <label class="mb-2">
                    <input type="checkbox" bind:checked={wishMulticlaim} />
                    Multi-claim
                </label>
                <WishLinksEdit bind:links={wishLinks} />
                <button type="button" class="bg-danger" on:click={deleteThis}>
                    <i class="fa-solid fa-trash-can" /> Delete
                </button>
                <button type="submit" hidden={!changed}>
                    <i class="fa-solid fa-floppy-disk" /> Save Changes
                </button>
            </fieldset>
        </form>
    {:else}
        <!-- Wish unowned view -->
        <div class="flex flex-col gap-2">
            <h3 class="text-2xl">
                {wish.name}
            </h3>
            {#if wish.description}
                <p class="">{wish.description}</p>
            {/if}
            {#if wishLinks?.length}
                <WishLinksView links={wishLinks} />
            {/if}
            {#if wishClaimants?.length}
                <h4 class="mt-4">Claimed by:</h4>
                {#each wishClaimants as claim}
                    <div class="flex flex-row items-center pl-2 gap-2">
                        <img
                            src={claim.avatar}
                            class="rounded-full max-h-10"
                            alt="{claim.name}'s avatar"
                        />
                        <span>
                            {claim._id === $user._id ? "Me" : claim.name}
                        </span>
                    </div>
                {/each}
            {/if}
            {#if $user && $user._id !== wish.owner && wishClaimants}
                {#if wishClaimants.length === 0 || (wish.multiclaim && !wishClaimants.find((c) => c._id === $user._id))}
                    <button class="mt-4" on:click={claim} disabled={saving}>
                        Claim this wish
                    </button>
                {:else if wishClaimants.find((c) => c._id === $user._id)}
                    <button
                        class="mt-4 bg-danger"
                        on:click={unclaim}
                        disabled={saving}
                    >
                        Unclaim this wish
                    </button>
                {/if}
                {#if wish.multiclaim}
                    <em class="mt-1 self-center">
                        Multi-claim: multiple people can claim this wish.
                    </em>
                {/if}
            {/if}
        </div>
    {/if}
{:catch}
    <Error msg="Wish not found ☹" />
{/await}
