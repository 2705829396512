import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";
import mixpanel from "mixpanel-browser";
import { createApi } from "unsplash-js";

Sentry.init({
    dsn: "https://af0de79555e5415992109028ae06d99a@o236107.ingest.sentry.io/6756190",
    integrations: [new BrowserTracing()],
    environment: import.meta.env.DEV ? "development" : "production",
    // release: import.meta.env.VITE_SENTRY_RELEASE,
    tracesSampleRate: import.meta.env.DEV ? 1.0 : 0.75,
    tunnel: import.meta.env.VITE_API_URL + "/steve",
});

try {
    mixpanel.init(
        import.meta.env.PROD
            ? import.meta.env.VITE_MIXPANEL_TOKEN ||
                  "226c8c052b45ce8836f36d21bb36d150"
            : "bogus",
        {
            persistence: "localStorage",
        }
    );
} catch {}

export const unsplash = createApi({
    apiUrl: import.meta.env.VITE_API_URL + "/unsplash-proxy",
});

const app = new App({
    target: document.getElementById("app"),
});

export default app;
