<script>
    import { user, backNavTarget } from "./store";
    import { link, navigate } from "svelte-navigator";
    import logo from "./assets/GiftKiwi.svg";

    console.log("ENV: " + import.meta.env.VITE_API_URL);
</script>

<nav class="mb-4 px-6 py-2 rounded-b-2xl flex flex-row justify-between">
    <div
        class="flex items-center px-6 {$backNavTarget ? 'cursor-pointer' : ''}"
        on:click={() => navigate($backNavTarget)}
    >
        <div class={$backNavTarget ? "opacity-100" : "opacity-0"}>
            <i class="fa-solid fa-arrow-left text-white text-2xl" />
        </div>
    </div>
    <div class="flex items-center justify-center w-20">
        <a class="flex flex-grow" href="/" use:link>
            <img src={logo} style="filter: invert(1);" alt="Gift Kiwi logo" />
        </a>
    </div>
    <div class="flex items-center justify-end w-20 p-2">
        {#if $user}
            <a href="/profile" use:link>
                <img
                    class="rounded-full"
                    src={$user.avatar}
                    alt="User avatar"
                />
            </a>
        {/if}
    </div>
</nav>

<style lang="scss">
    nav {
        background: rgb(200, 95, 0);
        background: -moz-linear-gradient(
            2deg,
            rgba(200, 95, 0, 1) 0%,
            rgba(240, 134, 38, 1) 100%
        );
        background: -webkit-linear-gradient(
            2deg,
            rgba(200, 95, 0, 1) 0%,
            rgba(240, 134, 38, 1) 100%
        );
        background: linear-gradient(
            2deg,
            rgba(200, 95, 0, 1) 0%,
            rgba(240, 134, 38, 1) 100%
        );
    }
</style>
