<div>
    <h1>Terms of Use</h1>
    <p>
        <strong>IMPORTANT – PLEASE READ CAREFULLY:</strong> This End User License
        Agreement (“Licence”) is a legal agreement between the party who is using
        or paying for the use of the Software (“You”) and Gift Kiwi.
    </p>
    <p>
        This Licence shall take effect from the time the Software is first used
        or Downloaded by You. By using or otherwise accessing the Software, You
        hereby acknowledge and accept the above recitals and the following terms
        and as they may be reasonably amended from time to time.
    </p>
    <h2>1. DEFINITIONS:</h2>
    <p>
        All capitalised terms shall have the meaning set forth in this Licence.
    </p>
    <p>
        “ACCOUNT INFORMATION” means Your unique login details and password code,
        which allows you to access the Software online.
    </p>
    <p>
        “AUTHORIZED DEVICES” means mobile phone handsets and other Supported
        Devices accessing the Software by an Authorized User.
    </p>
    <p>
        “CONTENT” means imagery or other forms of data submited through the
        service.
    </p>
    <p>
        “DATA” means the information pertaining to wishs and lists (including
        supplimental information e.g. description, photos), which is thereafter
        supplied to you by Gift Kiwi in machine readable form by the Software.
    </p>
    <p>“START DATE” means the date that You first register your account.</p>
    <p>“TERM” means the duration from the Start Date until termination.</p>
    <p>
        “SOFTWARE” means the system branded “Gift Kiwi” developed and
        proprietary to James Penman and consisting of “online” computer
        software, mobile applications and services provided via computers and
        devices connected to the Internet. It also includes all Data, updates,
        images and any and all associated media and printed materials including
        ‘online’ or electronic documentation.
    </p>
    <p>
        “SUPPORTED DEVICE” means smartphones, electronic tablets and computer
        equipment consisting of the minimum equipment specifications, operations
        systems and configuration required to run the Software.
    </p>
    <h2>2. TERM</h2>
    <p>
        The term of this Licence will commence on the Start Date and continue
        until terminated by either party.
    </p>
    <h2>4. LICENCE</h2>
    <p>
        4.1. Gift Kiwi hereby grants to You non-exclusive, non-transferable use
        of the Software subject to the terms and conditions of this Licence.
    </p>
    <p>
        4.2. The Software is intended to be used online in conjunction with a
        hosting service. You will be provided with a single set of Account
        Information. You may use your Account Information to access the Software
        on multiple devices. You may only use the Software in good faith and for
        proper purpose.
    </p>
    <p>
        4.3. You acknowledge that all copyright and all other ownership rights
        and goodwill in any and all parts of the Software and any replacement,
        update or modification of any part of the Software remain the sole and
        exclusive property of James Penman and/or its rightful licensors. NO
        PROPRIETARY RIGHTS ARE VESTED IN YOU. The Software at all times remains
        the property of James Penman and/or its rightful licensors.
    </p>
    <h2>5. USE OF THE SOFTWARE</h2>
    <p>
        5.1. You may not make any copies of any part or portion of the Software
        or copy or load its Data onto any distribution device or platform.
    </p>
    <p>5.2. You agree that you shall not:</p>
    <p>
        (i) make, or permit any other person to make, a copy of the Software,
        your Account Information or the Data or any part thereof;
    </p>
    <p>
        (ii) make use of any part of the Software or the Data for or on behalf
        of any unauthorized person, entity or organization, nor permit any
        unauthorized person, entity or organization to utilize the Software,
        Your Account Information or the Data;
    </p>
    <p>
        (iii) make or permit to be made any additions or modifications to the
        Software and/ or the Data. Any unauthorized modifications to the
        Software may be considered an infringement of copyright and other
        intellectual property laws;
    </p>
    <p>
        (iv) attempt in any manner to reverse engineer, decompile or disassemble
        the Software; and
    </p>
    <p>(v) access the Data by any means other than via the Software.</p>
    <p>
        5.3. The Software is entirely online-based and is resident on servers
        belonging to either Reactar or a Reactar service provider and is
        accessed wholly via your own Authorised Devices over the internet. You
        acknowledge that you will need Supported Devices running the minimum
        specifications recommended in order to access the Software, and that you
        are entirely responsible for obtaining, installing, configuring,
        updating and maintaining your own Supported Devices.
    </p>
    <p>
        5.4. On downloading the Software, You will be required to set up a user
        account which will allow You to access the Software via Your own
        Supported Device. You acknowledge and accept that you are solely
        responsible for maintaining the confidentiality of your Account
        Information and for restricting access to your Account Information. You
        further acknowledge and accept that you will be deemed responsible for
        any and all activities that occur in connection with your Account
        Information.
    </p>
    <p>
        5.5. If you become aware of any unauthorized use of your Account
        Information you must notify Reactar immediately. Reactar may, in its
        sole discretion, restrict, limit or alter the compromised Account
        Information so as to completely or partially disable access to the
        Software via the compromised Account Information. Reactar accepts no
        liability for any loss or damage caused by unauthorized use of any
        compromised Account Information, or by any inability to access the
        Software.
    </p>
    <p>
        5.6. You agree to respect and abide by all laws and regulations in force
        and commit not to violate public order or infringe on the rights of any
        third party.
    </p>
    <p>
        5.7. You acknowledges and accept that the implementation and use of Gift
        Kiwi applications and products requires a connection to the Internet, to
        have a device with the appropriate technology and that the functioning
        of Gift Kiwi depends directly on this connection for which you shall be
        individually and solely responsible.
    </p>
    <p>
        5.8. You undertake all necessary precautions when using a Gift Kiwi
        application. James Penman shall in no way be held liable in the event of
        any accident, especially related to a loss of concentration.
    </p>
    <p>
        5.9. You represent that you have all the necessary rights and
        authorizations on submitted Content. You shall not submit Content which
        belongs to any third party without the proper legal authorization.
    </p>
    <p>
        5.10. You have the right to use, modify, alter, disclose and make
        publicly available the content by Gift Kiwi, under the understanding you
        are the owner of any intellectual property rights related to the
        Content, or else have a license granted from the titleholder that
        entitles you to do so.
    </p>
    <p>
        5.11. You acknowledge and declare that the Content provided does not
        breach any intellectual property rights of any third party. You agree to
        hold Reactar free from any claim from by third parties alleging breach
        to supposed intellectual property rights related with the Content.
    </p>
    <p>
        5.12. You authorize Gift Kiwi to perform certain activities in relation
        to the Content for the functioning of the Products. You renounce
        claiming any type of payment, fee, royalty, indemnity or financial
        compensation from Gift Kiwi for carrying out any of the actions for the
        functioning of the Products.
    </p>
    <h2>6. SOFTWARE, DATA UPDATES AND SUPPORT</h2>
    <p>
        6.1. James Penman will perform customary updates to Software from time
        to time. James Penman shall release such updates in accordance with the
        receipt of the necessary information from you to provide relevant
        changes to Gift Kiwi. James Penman is under no obligation to perform,
        make or develop any specific enhancements to or customization of the
        Software, although James Penman may offer such enhancements to you
        during the term of this Licence.
    </p>
    <p>
        6.2. James Penman will provide access to their email support services
        during normal business hours for all application-related queries. James
        Penman may, at its sole discretion, investigate and, to the extent
        possible, correct any failure of the Software to meet the functional
        specifications in the instructions, provided that:
    </p>
    <p>(i) any failure shall be demonstrable;</p>
    <p>
        (ii) notice is given to James Penman promptly after any such failure
        becomes known;
    </p>
    <p>
        (iii) the operating system of supported devices, Authorized Equipment
        have been properly installed, operated, configured and maintained; and
    </p>
    <p>
        (iv) failure is not due to a fault in the Supported Device, Authorized
        Equipment or the fault of third party software not licensed by Gift
        Kiwi.
    </p>
    <p>
        6.3. Any replacement, update or modification of any part of the Software
        shall become part of the Software and your rights to use such
        replacement, update or modification shall be governed by the terms of
        this Licence. Any reference to the Software in this Licence shall
        include all such replacements, updates and modifications from time to
        time.
    </p>
    <h2>8. TERMINATION</h2>
    <p>
        8.1. You may terminate this Licence at any time, providing one (1)
        months notice, unless explicitly agreed otherwise.
    </p>
    <p>
        8.2. James Penman shall be entitled to terminate this Licence at any
        time following breach of any term of this Licence.
    </p>
    <p>
        8.3. On termination of this Licence access to your Account Information
        and the Software will be disabled without any liability to Reactar and
        you must immediately stop using the Software.
    </p>
    <h2>9. WARRANTIES</h2>
    <p>
        9.1. James Penman does not warrant the Content nor that the Software or
        the Data is error free. James Penman does not validate the accuracy or
        completeness of the Content, Data and information supplied to it by the
        Customer.
    </p>
    <p>
        9.2. You acknowledge that you have relied on your own commercial
        analysis and judgment in selecting the Software and have not relied on
        any promise, representation, warranty or undertaking made or given by
        James Penman in relation to any results, consequences or benefits to be
        obtained from the use of the Software.
    </p>
    <p>
        9.4. The Software is provided “as is” without warranty of any kind,
        either express or implied, including, but not limited to, the implied
        warranties of merchantability and fitness for a particular purpose. The
        Licensor does not warrant that the functions contained in the Software
        will meet your requirements or that the operation of the Software will
        be uninterrupted or error free. The entire risk as to the quality and
        performance of the Software is with you. If the Software is defective,
        you (not James Penman) assume the entire cost of all necessary
        servicing, repair or correction.
    </p>
    <p>
        9.5. Under no circumstances is James Penman liable to you for any
        damages, including any special, incidental, indirect or consequential
        damages arising out of the use of or inability to use the Software even
        if the Reactar has been advised in advance that such damages may arise,
        or for any claim by any other party.
    </p>
    <p>
        9.6. Subject to this section, all conditions and warranties which would
        or might otherwise be implied in this Licence are hereby excluded to the
        extent permitted by law.
    </p>
    <h2>10. FORCE MAJEURE</h2>
    <p>
        10.1. Neither James Penman nor You shall be liable for any failure to
        perform any obligations required to be completed by James Penman or you
        herein, where such failure is due to any cause beyond the reasonable
        control of James Penman or You, except for the making of payments as and
        when they fall due and any obligations pertaining to confidentiality.
    </p>
    <h2>11. GENERAL</h2>
    <p>
        11.1. James Penman is committed to protecting and safeguarding your
        privacy with respect to all personal information collected by it (refer
        to the privacy policy, including GDPR, on
        <a
            href="https://www.giftkiwi.app/privacy"
            target="_blank"
            rel="noopener noreferrer nofollow"
            >https://www.giftkiwi.app/privacy</a
        > for details). Accordingly, you hereby provide consent to the James Penman
        to use e-mail or other means of communication to provide You with information
        regarding software or services that are in use by you or that might be adopted
        and implemented by you.
    </p>
    <p>
        11.2. James Penman may, at any time, assign or sub-contract all or part
        of its rights and obligations under this Licence. This Licence may not
        be assigned or sub-contracted by You without the prior written
        authorization of James Penman.
    </p>
    <p>
        11.3. This Licence is governed by the laws of New Zealand and the
        parties submit to the jurisdiction of the Courts of New Zealand for
        determining any dispute concerning this Licence.
    </p>
    <p>
        11.4. This document constitutes the entire agreement and understanding
        between the parties in relation to the use and supply of the Software.
    </p>
    <p>
        For any questions or notices, please contact us at:
        <br />
        James Penman
        <br />
        Email: <a href="mailto:james.pen@live.com">james.pen@live.com</a>
    </p>
    <p>Updated: September 2022</p>
</div>

<style lang="scss">
    p {
        @apply my-3;
        a {
            @apply underline;
        }
    }

    h1,
    h2 {
        @apply mt-4;
    }

    ol {
        @apply list-decimal ml-8;
    }
</style>
