<div>
    <h1>Privacy Policy</h1>

    <p>
        For information about the New Zealand Privacy Act and how it protects
        all personal information in New Zealand, <a
            href="https://www.privacy.org.nz/privacy-act-2020/privacy-principles/"
            target="_blank"
            rel="noopener noreferrer nofollow">click here</a
        >.
    </p>

    <p>
        Your privacy is important to us. We want you to understand the terms and
        conditions surrounding the capture and use of any information we gather.
        To that end, this privacy policy sets out what information we gather,
        how we may use it, and how to correct or change it.
    </p>

    <h2>Collection</h2>

    <p>
        The Gift Kiwi service, accessed through our website or any other online
        medium (“Platform”) is subject to our various terms and conditions of
        use and this privacy policy. Gift Kiwi ("we" or "us") may gather the
        following types of information about users including you ("you"):
    </p>

    <ol>
        <li>
            personal information provided by you when you interact with us, such
            as when you use or purchase our products or services, when you
            subscribe or register to use our products or services, and when you
            provide us with your feedback or respond to surveys you are asked to
            participate in;
        </li>
        <li>
            personal information provided by you when you use the Platform, or
            post/upload it to your profile aggregated information generated by
            our systems or third party systems we use, which track traffic to
            and from our Platform but does not relate to you personally;
        </li>
        <li>other information that does not personally identify you.</li>
    </ol>

    <p>
        When setting up your Gift Kiwi account you will be asked a number of
        personal questions (name, email etc.). Failure to provide this necessary
        personal information may result in certain services not being available
        to you. You must ensure that all information you provide is correct and
        up-to-date. If you change your details, you agree to ensure your details
        are updated. If you have difficulties amending or updating your details,
        you can contact us at james.pen@live.com. Failure to provide other
        necessary personal information when requested may result in certain
        products or services not being available to you.
    </p>

    <h2>Disclosure of Information</h2>

    <p>We may disclose:</p>

    <ol>
        <li>
            aggregated tracking information and other information that does not
            personally identify you to third parties such as our partners,
            agents, and advertisers;
        </li>
        <li>
            your personal information to third parties when we believe in good
            faith that we are required to do so by law;
        </li>
        <li>
            your personal information to other third parties provided we have
            your prior authorisation, which we will usually obtain at the time
            of collecting the information from you.
        </li>
    </ol>

    <p>
        A list of other third parties who may be provided with information, and
        a description of how those third parties may use this information, is
        available on request.
    </p>

    <h2>Use</h2>

    <p>We may use the personal information we collect:</p>

    <ol>
        <li>
            for verification of your identity and assisting you in case you
            forget your password or login details where these are necessary to
            access any of our products or services;
        </li>
        <li>
            to assist in providing the products and services requested by you;
        </li>
        <li>
            to provide you with information about our updated services,
            including new Gift Kiwi offerings
        </li>
        <li>
            for future marketing, promotional and publicity purposes, including
            carrying out direct marketing, market research and surveys;
        </li>
        <li>for any other uses that you authorise.</li>
    </ol>

    <h2>Content Providers, Advertisers and Partners</h2>

    <p>
        We do not control the practices of partners connected with our Platform
        and to whom you provide personal information whilst using our Platform
        or services. Our Platform contains links to other sites, which may not
        follow the same privacy policies as us. We recommend that you check any
        relevant privacy policies before providing your personal information to
        any third party.
    </p>

    <h2>Cookies, Clear GIFS and Tracking</h2>

    <p>
        We may collect non-personal information about the computer, mobile
        telephone, smartphone or other device that you use to access our
        products or services. Where you allow our mobile services and products
        to deliver content based on your location (by enabling this feature on
        your mobile device) we may collect location data. This information is
        used only for the automated delivery of relevant content to you and for
        no other purpose.
    </p>

    <h2>Analytics</h2>

    <p>
        Gift Kiwi or our related companies contract with online measurement
        companies and other service providers to manage and optimise our
        Internet business and communications. These companies collect
        information about visits to our Platform to assist us in analysing the
        Platform's performance and optimising the Platform for users.
    </p>

    <h2>Social Media and APIs</h2>

    <p>
        We may provide an application programming interface (API) to enable
        software to interface with this Platform. The API enables developers to
        build new applications for our members to use. We use it to power our
        current web application and potentially future applications. Some
        applications enable you to interact with us through the API in a way
        that requires you to log in. To do this, most of these applications will
        direct you through a secure process, where you are able to let the
        application connect to your account.
    </p>

    <p>
        If you allow an application to connect to your Gift Kiwi account,
        including if you set up your account using an API with a third party
        social media platform, that application will be able to access
        information that you can see when you are logged in to Gift Kiwi. You
        should only allow applications you trust to access your Gift Kiwi
        account.
    </p>

    <p>
        If you set up your Gift Kiwi account using an API with a third party
        social media platform, you also consent to us obtaining and using your
        information from such platform.
    </p>

    <h2>Opting in and Opting out</h2>

    <p>
        If our intended collection, use or disclosure of your personal
        information is outside the collection, use or disclosure set out in this
        Privacy Policy we will give you the option to:
    </p>

    <ol>
        <li>
            opt out and not receive certain services or participate in certain
            interactive areas; or
        </li>
        <li>
            opt in, for example agreeing to be contacted by us in relation to
            certain matters such as notification of new features to this
            Platform, receiving newsletters or promotional activities.
        </li>
    </ol>

    <h2>Requesting Deletion of Data</h2>

    <p>
        You can request that your data be deleted from our systems by emailing
        james.pen@live.com and supplying the email address associated with your
        Gift Kiwi account. Your data will be deleted within 48 hours of the
        request being received.
    </p>

    <h2>Changes to Policy</h2>

    <p>
        We reserve the right to change this policy at any time by notifying
        Platform users of the existence of a revised privacy policy.
    </p>

    <p>Updated: September 2022</p>
</div>

<style lang="scss">
    p {
        @apply my-3;
        a {
            @apply underline;
        }
    }

    h1,
    h2 {
        @apply mt-4;
    }

    ol {
        @apply list-decimal ml-8;
    }
</style>
