import { user } from "./store";
import axios from "axios";
import fetchAdapter from "@vespaiach/axios-fetch-adapter";

const USER_AUTH_KEY = "userAuth";
const BASE_ROUTE = "/v1";

const ax = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    adapter: fetchAdapter,
});

/** @type {{authToken?: string, refreshToken?: string}} */
let userAuth = JSON.parse(window.localStorage.getItem(USER_AUTH_KEY)) || {};

if (userAuth.authToken)
    ax.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${userAuth.authToken}`;

function saveUserAuth() {
    ax.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${userAuth.authToken}`;
    window.localStorage.setItem(USER_AUTH_KEY, JSON.stringify(userAuth));
}

function deleteUserAuth() {
    delete ax.defaults.headers.common["Authorization"];
    window.localStorage.removeItem(USER_AUTH_KEY);
}

function setAuthFromResponse(res) {
    console.log("setAuthFromResponse");
    userAuth.authToken = res.data.authToken;
    userAuth.refreshToken = res.data.refreshToken;
    saveUserAuth();
}

ax.interceptors.response.use(
    (res) => res,
    async (error) => {
        const config = error.config;
        if (error.response) {
            if (
                error.response.status === 401 &&
                !config.url.endsWith("refreshToken") &&
                !config.url.endsWith("login")
            ) {
                console.log("Refreshing token");
                await refreshAuthToken();
                config.headers["Authorization"] =
                    ax.defaults.headers.common["Authorization"];
                console.log("Set new token, retrying with config");
                return ax(config);
            } else if (error.response.status === 401) {
                logout();
            }
        }
        return Promise.reject(error);
    }
);

async function refreshAuthToken() {
    const res = await ax.post(`${BASE_ROUTE}/auth/refreshToken`, {
        refreshToken: userAuth.refreshToken,
    });
    setAuthFromResponse(res);
    saveUserAuth();
}

export async function checkAuth() {
    console.log("Check auth");
    try {
        const u = await getProfile();
        user.set(u);
        return u;
    } catch (e) {
        console.log("Failed to get profile");
    }
    return null;
}

export async function register(email, password, name, captchaToken) {
    var res = await ax.post(`${BASE_ROUTE}/auth/register`, {
        email,
        password,
        name,
        captchaToken,
    });
    setAuthFromResponse(res);
    checkAuth();
}

export async function login(email, password) {
    const res = await ax.post(`${BASE_ROUTE}/auth/login`, { email, password });
    setAuthFromResponse(res);
    checkAuth();
    return res;
}

export function logout() {
    console.log("Logout");
    deleteUserAuth();
    user.set(null);
}

export async function resetPassword(email) {
    await ax.post(`${BASE_ROUTE}/auth/resetPassword`, { email });
}

export async function resetPasswordComplete(token, userId, password) {
    await ax.post(`${BASE_ROUTE}/auth/resetPasswordComplete`, {
        token,
        userId,
        password,
    });
}

export async function getProfile() {
    return (await ax.get(`${BASE_ROUTE}/user/profile`)).data;
}

export async function updateProfile(data) {
    return await ax.put(`${BASE_ROUTE}/user/profile`, data);
}

export async function getLists() {
    return (await ax.get(`${BASE_ROUTE}/content/list/all`)).data;
}

export async function getList(id) {
    return (await ax.get(`${BASE_ROUTE}/content/list/${id}`)).data;
}

export async function createList(name) {
    await ax.post(`${BASE_ROUTE}/content/list`, {
        name,
    });
}

/**
 * Update a list's info.
 * @param {string} id
 * @param {{
 *      name?: string,
 *      description?: string,
 *      bgImage?: {
 *          url: string,
 *          urlSmall: string,
 *          blurHash: string,
 *          attribName: string,
 *          attribUrl: string
 *      }
 * }} data
 */
export async function patchList(id, data) {
    await ax.patch(`${BASE_ROUTE}/content/list/${id}`, data);
}

/**
 * @param {string} listId
 * @param {{name?: string, description?: string}} wish
 */
export async function addWishToList(listId, wish) {
    await ax.post(`${BASE_ROUTE}/content/list/${listId}/wish`, wish);
}

export async function deleteList(listId) {
    await ax.delete(`${BASE_ROUTE}/content/list/${listId}`);
}

export async function addListBookmark(listId) {
    await ax.post(`${BASE_ROUTE}/content/list/${listId}/bookmark`);
}

export async function deleteListBookmark(listId) {
    await ax.delete(`${BASE_ROUTE}/content/list/${listId}/bookmark`);
}

/**
 * @returns {Promise<{list: {_id: string, name: string, owner: {_id: string, name: string}, bgImage?: {blurHash?: string, urlSmall?: string}}}[]>}
 */
export async function getListBookmarks() {
    return await (
        await ax.get(`${BASE_ROUTE}/content/list-bookmarks`)
    ).data;
}

export async function getWish(wishId) {
    return (await ax.get(`${BASE_ROUTE}/content/wish/${wishId}`)).data;
}

export async function patchWish(wishId, wish) {
    await ax.patch(`${BASE_ROUTE}/content/wish/${wishId}`, wish);
}

export async function deleteWish(wishId) {
    await ax.delete(`${BASE_ROUTE}/content/wish/${wishId}`);
}

export async function claimWish(wishId) {
    await ax.post(`${BASE_ROUTE}/content/wish/${wishId}/claim`);
}

export async function unclaimWish(wishId) {
    await ax.post(`${BASE_ROUTE}/content/wish/${wishId}/unclaim`);
}

export async function getOpenGraph(url) {
    return (
        await ax.get(`${BASE_ROUTE}/utils/og`, {
            params: {
                url,
            },
        })
    ).data;
}
