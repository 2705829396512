<script>
    import { backgroundImage } from "../store.js";
    import { patchList } from "../api";
    import { unsplash } from "../main.js";

    export let listId;

    let query = "";
    /** @type {import("node_modules/unsplash-js/dist/methods/photos/types").Basic[]} */
    let results = [];

    let saving = false;

    async function search() {
        const res = await unsplash.search.getPhotos({
            query: query.trim(),
            page: 1,
            perPage: 12,
        });
        if (res.errors) {
            console.error(res.errors);
        } else {
            results = res.response.results;
        }
    }

    /** @param {import("node_modules/unsplash-js/dist/methods/photos/types").Basic} result */
    async function set(result) {
        saving = true;
        try {
            const bgImage = {
                url: result.urls.regular,
                urlSmall: result.urls.small,
                blurHash: result.blur_hash,
                attribName: result.user.name,
                attribUrl: result.user.links.html,
            };
            await patchList(listId, {
                bgImage,
            });
            backgroundImage.set(bgImage);
            unsplash.photos.trackDownload({
                downloadLocation: result.links.download_location,
            });
        } finally {
            saving = false;
        }
    }
</script>

<h3>Set background image:</h3>
<p>
    Photos by <a
        class="underline"
        href="https://unsplash.com/?utm_source=giftkiwi&utm_medium=referral"
        target="_blank"
        rel="noopener">Unsplash</a
    >.
</p>

<form
    class="flex flex-row justify-center gap-2 mt-2"
    on:submit|preventDefault={search}
>
    <input
        type="text"
        class="flex-grow"
        placeholder="Search for photos..."
        bind:value={query}
    />
    <button type="submit">Search</button>
</form>
<div class="flex flex-row flex-wrap justify-center gap-4 mt-4">
    {#each results as r}
        <div
            class="flex flex-col justify-end bg-cover bg-center w-33 h-33 rounded-2xl overflow-hidden cursor-pointer shadow-lg"
            style="background-image: url({r.urls.small});"
        >
            <div class="flex-grow" on:click={() => set(r)} />
            <a
                id="thumbAttrib"
                class="gradient bg-dark-500 bg-opacity-40 text-light-50 px-3 py-1 underline"
                href={r.user.links.html +
                    "?utm_source=giftkiwi&utm_medium=referral"}
                target="_blank"
                rel="noopener"
            >
                {r.user.name}
            </a>
        </div>
    {/each}
</div>

<style>
    #thumbAttrib {
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: -webkit-linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }
</style>
