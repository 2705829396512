<script>
    import BackgroundCoverImage from "./BackgroundCoverImage.svelte";
    import NavBar from "./NavBar.svelte";
    import Login from "./Login.svelte";
    import { Route, Router } from "svelte-navigator";
    import { user } from "./store";
    import { checkAuth } from "./api";
    import Wishlists from "./Wishlists.svelte";
    import Wishlist from "./Wishlist.svelte";
    import Loading from "./lib/Loading.svelte";
    import { Modals, closeModal } from "svelte-modals";
    import PasswordReset from "./PasswordReset.svelte";
    import PrivacyPolicy from "./PrivacyPolicy.svelte";
    import TermsOfUse from "./TermsOfUse.svelte";
    import Profile from "./Profile.svelte";

    console.log("ENV: " + import.meta.env.VITE_API_URL);
</script>

<BackgroundCoverImage />

<!-- 'primary={false}' to disable ally.js because it constantly throws errors. -->
<Router primary={false}>
    <NavBar />
    <main class="flex flex-col mx-4 mb-10 max-w-screen-md md:mx-auto">
        <Route path="/about">
            <p>Some info about this app.</p>
        </Route>

        <Route path="/privacy">
            <PrivacyPolicy />
        </Route>

        <Route path="/terms-of-use">
            <TermsOfUse />
        </Route>

        <Route path="/passwordReset">
            <PasswordReset />
        </Route>

        <Route path="/*">
            {#await checkAuth()}
                <Loading />
            {:then}
                <!-- Public Routes -->
                <Route path="/list/:listId/*" component={Wishlist} let:params />
                <Route path="/*">
                    <!-- Private Routes -->
                    {#if $user}
                        <Route path="/">
                            <Wishlists />
                        </Route>

                        <Route path="/profile">
                            <Profile />
                        </Route>
                    {:else}
                        <Login />
                    {/if}
                </Route>
            {/await}
        </Route>
    </main>
</Router>

<Modals>
    <div
        slot="backdrop"
        class="fixed top-0 bottom-0 left-0 right-0 bg-dark-300 bg-opacity-50"
        on:click={closeModal}
    />
</Modals>

<style lang="scss" windi:preflights:global windi:safelist:global>
    :global(html) {
        @apply bg-light-50;
    }
    :global(body) {
        font-family: "Source Sans Pro", ui-sans-serif, system-ui, -apple-system,
            BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
            "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
            "Segoe UI Symbol", "Noto Color Emoji";
    }

    :global(.bg-danger) {
        @apply bg-red-300;
    }

    :global(button) {
        @apply shadow-md p-3 border-none rounded-full cursor-pointer bg-light-900;
    }

    :global(h1, h2, h3, h4) {
        @apply font-bold;
    }

    :global(h1) {
        @apply text-3xl;
    }

    :global(h2) {
        @apply text-2xl;
    }

    :global(h3) {
        @apply text-xl;
    }

    :global(h4) {
        @apply text-lg;
    }

    :global(input, textarea) {
        -webkit-appearance: none;
        @apply bg-light-50 shadow-inner shadow-gray-400 rounded-full p-3;
    }

    :global(textarea) {
        @apply rounded-3xl;
    }

    :global(input:disabled, textarea:disabled, button:disabled) {
        @apply text-gray-400 bg-gray-200;
    }

    :global(button:disabled) {
        @apply cursor-default;
    }

    :global(input[type="checkbox"]) {
        @apply shadow-none bg-transparent p-0;
        appearance: none;
        font-size: 1.5em;
    }

    :global(input[type="checkbox"]:before) {
        font-family: "Font Awesome 6 Free";
        content: "\f0c8";
    }

    :global(input[type="checkbox"]:checked:before) {
        // @apply bg-[rgb(240,134,38)];
        content: "\f14a";
    }
</style>
